import { Box, Typography } from '@mui/material';
import { whyWithUs } from '#/components/pages/Landing/landingData';
import WhyUsCard from '#/components/pages/Landing/Cards/WhyUsCard';
import useLocales from '#/hooks/useLocales';

const WhyWithUs = () => {
  const { translate } = useLocales();

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
      }}
    >
      <Typography
        variant="h2"
        textAlign="center"
        data-aos="fade-down"
        sx={{
          marginBottom: 5,
        }}
      >
        {String(translate('provisionLanding.whyWithUs.title'))}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr 1fr',
          },
          columnGap: 10,
          rowGap: 5,
        }}
      >
        {whyWithUs.map((item, i) => (
          <WhyUsCard {...item} key={i} />
        ))}
      </Box>
    </Box>
  );
};

export default WhyWithUs;
