import { Button, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useLocales from '#/hooks/useLocales';

type NewsletterCardProps = {
  title: string;
  buttonLabel: string;
  url?: string;
};
const NewsletterCard = ({ title, buttonLabel, url }: NewsletterCardProps) => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleButtonClick = (url?: string) => {
    if (!url) return;
    navigate(url);
  };

  return (
    <Card
      sx={{
        py: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
        gap: 4,
      }}
    >
      <Typography variant="h4" color="white">
        {String(translate(title))}
      </Typography>
      <Button
        onClick={() => handleButtonClick(url)}
        variant="outlined"
        sx={{
          backgroundColor: '#fff',
          color: 'black',
          '&:hover': {
            backgroundColor: 'black',
            color: 'white',
          },
        }}
      >
        <Typography variant="body1">
          {String(translate(buttonLabel))}
        </Typography>
      </Button>
    </Card>
  );
};

export default NewsletterCard;
