import { Box, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import WhyUsCard from '#/components/pages/Landing/Cards/WhyUsCard';
import { eightReasons } from '#/components/pages/Landing/landingData';

type Props = {
  service?: string;
};

export default function EightReasons({ service }: Props) {
  const { translate } = useLocales();

  const isMain = service === 'main';

  console.log('service: ', service);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1500,
        margin: '0 auto',
        alignSelf: 'center',
      }}
    >
      <Typography
        variant="h2"
        textAlign="center"
        data-aos="fade-down"
        sx={{
          marginBottom: 5,
        }}
      >
        {String(translate('provisionLanding.eightReasons.title'))}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr 1fr 1fr',
          },
          columnGap: 10,
          rowGap: 5,
        }}
      >
        {eightReasons.map((item, i) => (
          <WhyUsCard
            {...item}
            description={item.description(
              isMain ? 'provisionLanding' : service || ''
            )}
            key={i}
          />
        ))}
      </Box>
    </Box>
  );
}
