import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import {
  landingBanners,
  legallyCompliant,
  pensionProvisionBanner,
} from './landingData';
import Banner from './Banner';
import WhyWithUs from '#/components/pages/Landing/WhyWithUs';
import WhatIs from '#/components/pages/Landing/WhatIs';
import Testimonials from '#/components/pages/Landing/Testimonials';
import Pricing from '#/components/pages/Landing/Pricing';
import Newsletter from '#/components/pages/Landing/Newsletter';
import PartnerForm from '#/components/pages/Landing/partner/PartnerForm';
import Solutions from '#/components/pages/Landing/partner/solutions';
import FAQ from '#/components/pages/Landing/FAQ';
import { Angebot } from '#/components/pages/Landing/grosseitern-magazine';
import { useSendPageVisit } from '#/api/partnerQueries';
import EightReasons from '#/components/pages/Landing/EightReasons';

type Props = {
  title: string | undefined;
};

const Landing = ({ title }: Props) => {
  const { hostname } = window.location;
  const domainParts = hostname.split('.');
  const subdomain =
    domainParts.length > 2 ? domainParts.slice(0, -2).join('.') : '';

  const validSubdomain =
    subdomain !== 'www' &&
    subdomain !== 'localhost' &&
    subdomain !== 'dev' &&
    subdomain !== '';

  const { mutateAsync: sendPageVisit } = useSendPageVisit(subdomain);

  const handlePageVisitIncrement = async () => {
    await sendPageVisit({ partner_name: subdomain });
  };

  useEffect(() => {
    if (title === 'main' && validSubdomain) {
      handlePageVisitIncrement();
    }
  }, [title, subdomain]);

  return (
    <Stack spacing={18}>
      {landingBanners
        .filter((banner) => banner.title === title)
        .map((banner) => (
          <Banner
            key={banner.title}
            title={banner.title}
            intro={banner.bannerIntro}
            heading={banner.bannerHeading}
            subHeading={banner.bannerSubHeading}
            buttonLabel={banner.bannerButton}
            image={banner.bannerImage}
            url={banner.url}
            heroBanner
            trustLaurel={banner.trustLaurel}
            waitlist={banner.waitlist}
            youtubePlayer={banner.youtubePlayer}
          />
        ))}
      {/* {title !== 'grosseltern' && */}
      {/*  youtubeTutorials */}
      {/*    .filter((tutorial) => tutorial.service === title) */}
      {/*    .map((tutorial) => ( */}
      {/*      <VideoIntroduction */}
      {/*        key={tutorial.service} */}
      {/*        title={tutorial.title} */}
      {/*        // eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*        // @ts-expect-error */}
      {/*        url={tutorial.url[currentLang.value]} */}
      {/*      /> */}
      {/*    ))} */}
      <WhatIs service={title} />
      {title !== 'partner-werden' && title !== 'grosseltern-magazin' && (
        <WhyWithUs />
      )}
      {title !== 'partner-werden' &&
        title !== 'grosseltern-magazin' &&
        title !== 'vermoegens-und-sachregister' && (
          <EightReasons service={title} />
        )}
      {title !== 'partner-werden' && title !== 'grosseltern-magazin' && (
        <Testimonials title={title} />
      )}
      {legallyCompliant
        .filter((banner) => banner.title === title)
        .map((item, i) => (
          <Banner
            key={i}
            heading={item.bannerHeading}
            title={item.title}
            subHeading={item.bannerSubHeading}
            buttonLabel={item.bannerButton}
            image={item.bannerImage}
            imagePosition={item.imagePosition || 'left'}
            url={item.url}
          />
        ))}

      {title === 'main' && <Pricing />}
      {title === 'main' && (
        <Banner
          title={pensionProvisionBanner.title}
          heading={pensionProvisionBanner.bannerHeading}
          subHeading={pensionProvisionBanner.bannerSubHeading}
          buttonLabel={pensionProvisionBanner.bannerButton}
          image={pensionProvisionBanner.bannerImage}
          url={pensionProvisionBanner.url}
        />
      )}
      {title === 'partner-werden' && <Solutions />}
      {title === 'partner-werden' && <PartnerForm />}
      {title !== 'main' && title !== 'grosseltern-magazin' && (
        <FAQ service={title} />
      )}
      {title !== 'grosseltern-magazin' && <Newsletter service={title} />}
      {title === 'grosseltern-magazin' && <Angebot />}
    </Stack>
  );
};

export default Landing;
