import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useLocales from '#/hooks/useLocales';
import Image from '#/components/shared/ui/Image';

type WhyUsCardProps = {
  title: string;
  description: string;
  icon: string;
};

const WhyUsCard = ({ title, description, icon }: WhyUsCardProps) => {
  const theme = useTheme();
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
      data-aos="flip-left"
      data-aos-duration="900"
    >
      <Image
        src={icon}
        color={theme.palette.primary.main}
        sx={{
          width: '50px !important',
        }}
      />
      <Typography
        component="h5"
        variant="h6"
        textAlign="center"
        sx={{
          height: '45px',
        }}
      >
        {String(translate(title))}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        textAlign="center"
        sx={{
          fontSize: '1rem',
        }}
      >
        {String(translate(description))}
      </Typography>
    </Box>
  );
};

export default WhyUsCard;
